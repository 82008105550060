<template>
  <div class="center">
    <b-container fluid>
      <div id="step1">
        <h1>Skapa din offert här!</h1>
        <p>Börja med att berätta för oss om du är en privatperson eller ett företag. Om du vill se en detaljerad prislista så kan du <router-link :to="{ name: 'price' }">klicka här</router-link>.</p>
        <div class="button-center">
          <b-button @click="step1('private')">Privat</b-button><b-button @click="step1('company')">Företag</b-button>
        </div>
      </div>
      <div id="step2" v-if="showStep2">
        <h2>Vet du hur dina detaljer tiddigare har målats?</h2>
        <div class="button-center">
          <b-button @click="step2('industrial')">Industrilackerade</b-button><b-button @click="step2('hand')">Handmålade</b-button><b-button @click="step2('clear')">klarlackade</b-button>
        </div>
      </div>
      <div id="step3" v-if="showStep3">
        <h2>Vad är det du behöver ha lackat?</h2>
        <b-row>
          <b-col cols="3">
            <b-form-input id="input-1" type="number" size="sm"></b-form-input>
          </b-col>
          <b-col cols="9" style="text-align:left">
            <label for="input-1">Luckor</label>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import { BButton, BFormInput } from 'bootstrap-vue'
export default {
  name: 'InteractiveTenderView',
  components: {
    BButton,
    BFormInput 
  },
  data: function() {
    return {
      customerType: '',
      showStep2: false,
      previouslyPainted: '',
      showStep3: false
    }
  },
  methods: {
    step1(customerType) {
      this.customerType = customerType
      this.showStep2 = true
    },
    step2(previouslyPainted) {
      this.previouslyPainted = previouslyPainted
      this.showStep3 = true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.button-center {
  text-align: center;
  .btn {
    margin: 10px;
  }
}
</style>
